@import '../../styles/breakpoint.mixin.scss';
@import '../../styles/colors.scss';

.sidebar {
  &__logo {
    display: none;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    img {
      max-width: 130px;
    }
  }

  &__inner {
    position: sticky;
    top: 30px;
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    @include breakpoint('l') {
      max-height: 100%;
      overflow-y: visible;
    }
  }

  &__title {
    margin: 0;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 1.125rem;
  }

  &__avatar {
    display: none;
    margin-top: 1em;
  }

  .nav-menu > li {
    padding-left: 0;
  }

  ul {
    li {
      margin: 0.3rem 0;

      &:before {
        content: '';
        display: none;
      }

      a {
        font-weight: 500;
        font-size: 0.875rem;

        &.active {
          font-weight: 700;
        }
      }
    }
  }

  .submenu {
    display: none;
    margin: 0.2rem 0 0.2rem -1rem;
    padding: 0.875rem 0 0.875rem 0.875rem;
    background: #e8e8e8;
  }

  .active + .submenu {
    display: block;
  }

  @include breakpoint('l') {
    position: fixed !important;
    top: 0;
    width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh;
    background-color: $color-silicium-blue;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 3;
    color: #fff;
    overflow-y: scroll;
    padding: 24px;

    &__inner {
      position: relative;
      top: 0;
    }

    &__logo {
      display: flex;
    }

    &__avatar {
      display: block;
    }

    ul {
      border-bottom: 1px solid $color-grey-blue;

      li {
        &:last-of-type {
          margin-bottom: 1em;
        }

        a {
          color: #fff;
        }
      }
    }

    .submenu {
      background: $color-grey-blue;
    }

    &.--open {
      transform: translateX(0);
    }
  }
}
