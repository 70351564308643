@font-face {
  font-family: 'BrandonText';
  src: url('../static/fonts/BrandonText-Thin.otf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'BrandonText';
  src: url('../static/fonts/BrandonText-ThinItalic.otf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'BrandonText';
  src: url('../static/fonts/BrandonText-Light.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'BrandonText';
  src: url('../static/fonts/BrandonText-LightItalic.otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'BrandonText';
  src: url('../static/fonts/BrandonText-Regular.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'BrandonText';
  src: url('../static/fonts/BrandonText-RegularItalic.otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'BrandonText';
  src: url('../static/fonts/BrandonText-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'BrandonText';
  src: url('../static/fonts/BrandonText-BoldItalic.otf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'BrandonText';
  src: url('../static/fonts/BrandonText-Black.otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'BrandonText';
  src: url('../static/fonts/BrandonText-BlackItalic.otf');
  font-weight: 900;
  font-style: italic;
}
