@import '../../styles/colors.scss';
@import '../../styles/breakpoint.mixin.scss';

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__container {
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
    z-index: 2;
  }

  &__header {
    background: $color-silicium-blue;
    padding: 14px 20px;

    img {
      display: block;
      width: 130px;
      height: auto;
    }
  }

  &__content {
    padding: 48px;
    text-align: center;

    form .fieldGroup {
      text-align: left;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      display: inline-block;
      margin-top: 12px;
    }
  }

  &__welcome {
    img {
      display: block;
      max-width: 120px;
      margin: 0 auto;
    }

    p {
      font-size: 1.125rem;
      font-weight: 300;

      span {
        display: block;
      }
    }
  }

  &__error {
    color: #ff4a4a;
    font-weight: bold;
  }

  &__footer {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 200px;
      background: -webkit-linear-gradient(
        bottom,
        rgba(0, 0, 0, 0.85),
        transparent
      );
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.85), transparent);
    }

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 1440px;
      padding: 0 30px;
      margin: 0 auto;
      z-index: 1;

      @include breakpoint('s') {
        flex-direction: column;
      }

      p {
        color: #fff;
        margin: 0;
        font-size: 0.75rem;
      }

      a {
        color: #fff;
        margin: 0 10px;
        font-weight: 500;
        font-size: 0.75rem;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
