@import '../../styles/colors.scss';

.license-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 2;

  &__inner {
    width: 100%;
    max-width: 760px;
    padding: 30px;
  }

  &__button {
    margin-top: 24px;
    outline: 0;
    padding: 10px 80px;
    border: 2px solid $color-silicium-blue;
    border-radius: 4px;
    text-align: center;
    transition: all 0.2s ease-out;
    font-size: 1em;
    cursor: pointer;

    &:hover {
      border-color: $color-sonnen-blue;
      color: $color-sonnen-blue;
    }
  }
}
