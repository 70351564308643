@import '../../styles/breakpoint.mixin.scss';
@import '../../styles/colors.scss';

.search-bar {
  position: relative;

  height: 30px;
  overflow: hidden;

  backface-visibility: hidden;

  @include breakpoint('s') {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.--open {
    width: 100%;

    @include breakpoint('s') {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: $color-silicium-blue;
    }
  }

  &__input[type='text'] {
    display: block;
    width: 0;
    height: 30px;
    background: #9396a1;
    opacity: 0;
    padding: 10px 15px;
    margin: 0;
    border-radius: 20px;
    border: 0;
    font-family: inherit;
    font-weight: 500;
    font-size: 0.875rem;
    color: #181a27;
    z-index: 10;
    transition: width 0.3s, opacity 0.3s;

    @include breakpoint('s') {
      pointer-events: all;
    }

    &::placeholder {
      color: #181a27;
      opacity: 0.4;
    }

    &:focus {
      outline: none;
    }

    .--open & {
      width: 200px;
      opacity: 1;

      @include breakpoint('s') {
        width: 77vw;
      }
    }
  }

  &__icon {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0;
    top: 6px;
    padding: 0;
    margin: 0;
    cursor: pointer;

    @include breakpoint('s') {
      pointer-events: all;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 28px;
      right: 58px;
    }
  }

  svg {
    width: 14px;
    height: auto;
  }
}
