@import '../../styles/colors.scss';

.download-button {
  .tippy-tooltip {
    background: #fff;
    border: 2px solid $color-sonnen-blue;
    padding: 8px 2px;
  }

  &__wrapper {
    padding: 4px 12px;
    border: 2px solid $color-silicium-blue;
    border-radius: 4px;
    transition: all 0.2s ease-out;
    text-align: center;
    cursor: pointer;
  }

  &__button {
    color: $color-silicium-blue;
  }

  &:hover &__wrapper {
    border-color: $color-sonnen-blue;
  }

  &:hover &__button {
    color: $color-sonnen-blue;
  }

  .source-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a {
      color: $color-sonnen-blue;
      font-weight: 500;
      padding: 2px 8px;
      border-radius: 4px;

      &:hover {
        background-color: #e6f9ff;
        text-decoration: none;
      }
    }
  }
}
