$grid-gutter-width: 48px;
$container-max-widths: (
  sm: 1440px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 770px,
  lg: 1024px,
  xl: 1440px,
);

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/grid';

@import './breakpoint.mixin.scss';

@import './colors.scss';
@import './fonts.scss';
@import './typography.scss';
@import './forms.scss';
@import './tables.scss';
@import './helpers.scss';
@import './vc_overwrites.scss';

body {
  margin: 0;
  padding: 0;

  &.--no-scroll {
    height: 100vh;
    overflow-y: hidden;
  }
}

* {
  box-sizing: border-box;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

p,
ul,
ol {
  line-height: 1.5rem;
}

ul {
  list-style: none;
  padding-left: 1rem;

  &:not(.nav-menu) {
    li {
      display: table-row;

      &:before {
        content: '\2022';
        color: $color-sonnen-blue;
        font-weight: bold;
        display: table-cell;
        width: 1em;
      }
    }
  }
}

a {
  font-weight: bold;
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.main-container {
  min-height: calc(100vh - 80px);
}
