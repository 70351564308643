body {
  font-family: 'BrandonText', -apple-system, BlinkMacSystemFont, Helvetica Neue,
    sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: $color-ground-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-silicium-blue;
  font-weight: 300;
}

h1 {
  font-size: 2.625rem;
  line-height: 3.125rem;
  margin: 0.75rem 0;
}

h2 {
  font-size: 1.75rem;
  line-height: 2.215rem;
}

h3 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 500;
}

h4 {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
}
