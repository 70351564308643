@import '../../styles/colors.scss';

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}

@keyframes bouncing-loader-small {
  to {
    opacity: 0.1;
    transform: translate3d(0, -10px, 0);
  }
}

.loader {
  text-align: center;
  margin: 50px;

  .bouncingLoader > div,
  .bouncingLoader:before,
  .bouncingLoader:after {
    display: inline-block;
    width: 13px;
    height: 13px;
    background: $color-sonnen-blue;
    border-radius: 50%;
    animation: bouncing-loader 0.6s infinite alternate;
  }

  .bouncingLoader > div,
  .bouncingLoader:before,
  .bouncingLoader:after {
    content: ' ';
  }

  .bouncingLoader > div {
    margin: 0 5px;
    animation-delay: 0.2s;
  }

  .bouncingLoader:after {
    animation-delay: 0.4s;
  }

  &.--small {
    margin: 0;

    .bouncingLoader > div,
    .bouncingLoader:before,
    .bouncingLoader:after {
      width: 6px;
      height: 6px;
      animation: bouncing-loader-small 0.6s infinite alternate;
    }
    .bouncingLoader > div {
      margin: 0 2px;
      animation-delay: 0.2s;
    }

    .bouncingLoader:after {
      animation-delay: 0.4s;
    }
  }

  &.--grey {
    .bouncingLoader > div,
    .bouncingLoader:before,
    .bouncingLoader:after {
      background: $color-grey-30;
    }
  }
}
