form,
form button,
button {
  font-family: 'BrandonText', -apple-system, BlinkMacSystemFont, Helvetica Neue,
    sans-serif;
}

form input {
  &[type='text'],
  &[type='password'] {
    height: 48px;
    border-radius: 4px;
    border: 1px solid #5e6373;
    padding: 12px;
    font-size: 1.125rem;

    &::placeholder {
      color: #c9cbd0;
    }

    &:active,
    &:focus {
      border-color: $color-sonnen-blue;
      outline: none;
    }
  }
}

.fieldGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  label {
    color: #4a4a4a;
    font-size: 0.875rem;
    margin-bottom: 6px;
  }
}

form button {
  border: 2px solid $color-silicium-blue;
  border-radius: 4px;
  padding: 8px 50px;
  font-size: 1.125rem;
  cursor: pointer;
  background: $color-silicium-blue;
  color: #ffffff;

  &:hover {
    color: $color-silicium-blue;
    border-color: $color-sonnen-blue;
    background: $color-sonnen-blue;
  }
}

.sonnen-button {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  border: 2px solid $color-silicium-blue;
  padding: 12px 40px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    border-color: $color-sonnen-blue;
    color: $color-sonnen-blue;

    svg {
      rect,
      path,
      line,
      polyline {
        stroke: $color-sonnen-blue;
      }
    }
  }

  svg {
    display: block;
    user-select: none;
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }

  span {
    font-weight: 500;
    white-space: nowrap;
    user-select: none;
  }
}
